var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "seatSelectionDialog-page" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "950px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogTitle !== "查看座位布局"
            ? _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "140px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "座位布局名称：", prop: "seatName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入座位布局名称" },
                        model: {
                          value: _vm.ruleForm.seatName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "seatName", $$v)
                          },
                          expression: "ruleForm.seatName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "座位排数：", prop: "seatRow" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.dialogTitle === "编辑座位布局",
                                placeholder: "请选择座位排数",
                              },
                              model: {
                                value: _vm.ruleForm.seatRow,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "seatRow", $$v)
                                },
                                expression: "ruleForm.seatRow",
                              },
                            },
                            _vm._l(_vm.seatRowOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "每排最大座位数：", prop: "seatCol" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.dialogTitle === "编辑座位布局",
                                placeholder: "请选择每排最大座位数",
                              },
                              model: {
                                value: _vm.ruleForm.seatCol,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "seatCol", $$v)
                                },
                                expression: "ruleForm.seatCol",
                              },
                            },
                            _vm._l(_vm.seatColOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "seat-selection-box" }, [
            _c("div", { staticClass: "box-left" }, [
              _c("div", { staticStyle: { "margin-bottom": "12px" } }, [
                _c("span", { staticClass: "left-label" }, [_vm._v("座位数：")]),
                _c("span", [_vm._v(_vm._s(_vm.seatNumber))]),
              ]),
              _c("div", [
                _c("span", { staticClass: "left-label" }, [_vm._v("票位：")]),
                _c("span", [_vm._v(_vm._s(_vm.ticketNumber))]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "box-mid",
                style: { width: _vm.midBoxHeight + "px" },
              },
              _vm._l(_vm.seatData, function (row, rowIndex) {
                return _c(
                  "div",
                  {
                    key: rowIndex,
                    staticClass: "row-box",
                    class: {
                      "aisle-box": row.id === -1,
                      "passenger-box": row.isHoverFlag,
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.handleMouseover(row)
                      },
                      mouseleave: function ($event) {
                        return _vm.handleMouseleave(row)
                      },
                    },
                  },
                  [
                    row.id >= 0
                      ? _c(
                          "div",
                          {
                            class: {
                              "bg-box": true,
                              "selected-bg":
                                row.id === 0 || (row.id > 0 && row.type === 1),
                              "not-selected-bg": row.id > 0 && row.type === 2,
                            },
                          },
                          [_vm._v(" " + _vm._s(row.id ? row.id : "") + " ")]
                        )
                      : _vm._e(),
                    _vm.dialogTitle === "新增座位布局" && rowIndex !== 0
                      ? _c(
                          "el-dropdown",
                          {
                            attrs: { placement: "bottom-start" },
                            on: {
                              command: function ($event) {
                                return _vm.handleSeatBox($event, rowIndex)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _c("i", { staticClass: "el-icon-more" }),
                            ]),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "edit" } },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "delete" } },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "box-right" }, [
              _c("div", { staticStyle: { "margin-top": "16px" } }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/home/selected.svg"),
                  },
                }),
                _c("div", [_vm._v("驾驶位")]),
              ]),
              _c("div", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/home/not_selected.svg"),
                  },
                }),
                _c("div", [_vm._v("乘客座位")]),
              ]),
              _c("div", [
                _c("div", { staticClass: "aisle-box" }),
                _c("div", [_vm._v("过道/未设置")]),
              ]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.dialogTitle !== "查看座位布局"
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleDialog("cancel")
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialog("save")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.dialogTitle !== "查看座位布局" ? "保 存" : "确 认"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "配置座位",
            visible: _vm.configureDialogVisible,
            "close-on-click-modal": false,
            width: "430px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.configureDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.form, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "座位类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.seatType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "seatType", $$v)
                        },
                        expression: "form.seatType",
                      },
                    },
                    _vm._l(_vm.seatTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.seatType === 2
                ? _c(
                    "el-form-item",
                    { staticClass: "seatNum", attrs: { label: "座位编号：" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          min: 1,
                          max: 99,
                        },
                        model: {
                          value: _vm.form.seatNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "seatNum", $$v)
                          },
                          expression: "form.seatNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleConfigureDialog("cancel")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfigureDialog("save")
                    },
                  },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }