<template>
  <div class="seatSelectionDialog-page">
    <!-- 座位布局弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
    >
      <el-form
        v-if="dialogTitle !== '查看座位布局'"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="座位布局名称：" prop="seatName">
          <el-input
            v-model="ruleForm.seatName"
            placeholder="请输入座位布局名称"
          ></el-input>
        </el-form-item>
        <div style="display: flex">
          <el-form-item label="座位排数：" prop="seatRow">
            <el-select
              v-model="ruleForm.seatRow"
              :disabled="dialogTitle === '编辑座位布局'"
              placeholder="请选择座位排数"
            >
              <el-option
                v-for="item in seatRowOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="每排最大座位数：" prop="seatCol">
            <el-select
              v-model="ruleForm.seatCol"
              :disabled="dialogTitle === '编辑座位布局'"
              placeholder="请选择每排最大座位数"
            >
              <el-option
                v-for="item in seatColOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="seat-selection-box">
        <div class="box-left">
          <div style="margin-bottom: 12px">
            <span class="left-label">座位数：</span>
            <span>{{ seatNumber }}</span>
          </div>
          <div>
            <span class="left-label">票位：</span>
            <span>{{ ticketNumber }}</span>
          </div>
        </div>
        <div :style="{ width: midBoxHeight + 'px' }" class="box-mid">
          <div
            v-for="(row, rowIndex) in seatData"
            :key="rowIndex"
            :class="{
              'aisle-box': row.id === -1,
              'passenger-box': row.isHoverFlag,
            }"
            class="row-box"
            @mouseover="handleMouseover(row)"
            @mouseleave="handleMouseleave(row)"
          >
            <!-- 座位图片 -->
            <div
              v-if="row.id >= 0"
              :class="{
                'bg-box': true,
                'selected-bg': row.id === 0 || (row.id > 0 && row.type === 1),
                'not-selected-bg': row.id > 0 && row.type === 2,
              }"
            >
              {{ row.id ? row.id : "" }}
            </div>
            <el-dropdown
              v-if="dialogTitle === '新增座位布局' && rowIndex !== 0"
              placement="bottom-start"
              @command="handleSeatBox($event, rowIndex)"
            >
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit">编辑</el-dropdown-item>
                <el-dropdown-item command="delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="box-right">
          <div style="margin-top: 16px">
            <img src="../../../assets/images/home/selected.svg" />
            <div>驾驶位</div>
          </div>
          <div>
            <img src="../../../assets/images/home/not_selected.svg" />
            <div>乘客座位</div>
          </div>
          <div>
            <div class="aisle-box"></div>
            <div>过道/未设置</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="dialogTitle !== '查看座位布局'"
          @click="handleDialog('cancel')"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleDialog('save')">{{
          dialogTitle !== "查看座位布局" ? "保 存" : "确 认"
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 配置座位弹窗 -->
    <el-dialog
      title="配置座位"
      :visible.sync="configureDialogVisible"
      :close-on-click-modal="false"
      width="430px"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="座位类型：">
          <el-select v-model="form.seatType">
            <el-option
              v-for="item in seatTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.seatType === 2"
          label="座位编号："
          class="seatNum"
        >
          <el-input-number
            v-model="form.seatNum"
            controls-position="right"
            :min="1"
            :max="99"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleConfigureDialog('cancel')">取 消</el-button>
        <el-button type="primary" @click="handleConfigureDialog('save')"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveSeatPlanAPI,
  querySeatPlanInfoAPI,
  updateSeatPlanAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {
    channelNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      seatRowOptions: [
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 },
      ],
      seatColOptions: [
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
      ],
      seatData: [],
      ruleForm: {
        seatName: "",
        seatRow: null,
        seatCol: null,
        seatNumber: null, //座位数
        ticketNumber: null, //票位
      },
      rules: {
        seatName: [
          { required: true, message: "请输入座位布局名称", trigger: "blur" },
        ],
        seatRow: [
          { required: true, message: "请选择座位排数", trigger: "change" },
        ],
        seatCol: [
          {
            required: true,
            message: "请选择每排最大座位数",
            trigger: "change",
          },
        ],
      },
      configureDialogVisible: false,
      handleSeatBoxIndex: null,
      signSeatNum: null,
      form: {
        seatType: 2,
        seatNum: 1,
      },
      seatTypeList: [
        { value: 2, label: "乘客座位" },
        { value: 1, label: "司机座位" },
      ],
    };
  },
  filters: {},
  computed: {
    midBoxHeight() {
      return Number(this.ruleForm.seatCol) * 100;
    },
    //获取座位图list
    getSeatList() {
      if (this.dialogTitle !== "新增座位布局") return;
      let num = Number(this.ruleForm.seatRow) * Number(this.ruleForm.seatCol);
      let arr = [];
      // isHoverFlag  鼠标移入移出座位时的标识
      for (let index = 0; index < num; index++) {
        //index === 0 司机的
        if (index === 0) {
          arr.push({ id: 0, type: 1, isHoverFlag: false });
        } else {
          arr.push({ id: -1, type: 0, isHoverFlag: false });
        }
      }
      return arr;
    },
    //座位数
    seatNumber() {
      let arr = this.seatData.filter((item) => {
        return item.id > -1;
      });
      return arr.length;
    },
    //票位
    ticketNumber() {
      let arr = this.seatData.filter((item) => {
        return item.type == 2;
      });
      return arr.length;
    },
  },
  watch: {
    getSeatList(val) {
      if (this.dialogTitle !== "新增座位布局") return;
      this.seatData = val;
    },
    dialogVisible(val) {
      if (!val) {
        this.seatData = [];
        this.ruleForm = this.$options.data().ruleForm;
        this.form = this.$options.data().form;
        this.$refs.ruleForm?.resetFields();
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    //打开座位布局弹窗
    openDialog(title, id) {
      this.dialogVisible = true;
      this.dialogTitle = title;
      if (title === "新增座位布局") {
        this.ruleForm.seatRow = 4;
        this.ruleForm.seatCol = 3;
      } else if (title === "编辑座位布局" || title === "查看座位布局") {
        querySeatPlanInfoAPI({ id }).then((res) => {
          if (res.code == "SUCCESS") {
            let obj = {};
            const data = res.data;
            obj.seatName = data.seatName;
            obj.seatRow = data.seatRow;
            obj.seatCol = data.seatCol;
            obj.seatNumber = data.seatNumber;
            obj.ticketNumber = data.ticketNumber;
            obj.id = data.id;
            obj.status = data.status;
            this.seatData = data.seatData;
            this.ruleForm = obj;
          }
        });
      }
    },
    //操作座位布局弹窗
    handleDialog(str) {
      if (str === "save") {
        if (this.dialogTitle === "查看座位布局") {
          this.dialogVisible = false;
          return;
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (this.dialogTitle === "新增座位布局") {
              this.ruleForm.seatNumber = this.seatNumber;
              this.ruleForm.ticketNumber = this.ticketNumber;
              let params = this.deepClone(this.ruleForm);
              params.seatData = this.seatData;
              params.channelNo = this.channelNo;
              saveSeatPlanAPI(params).then((res) => {
                if (res.code == "SUCCESS") {
                  this.dialogVisible = false;
                  this.$parent.getQuerySeatPlanPage();
                }
              });
            } else if (this.dialogTitle === "编辑座位布局") {
              let params = {
                status: this.ruleForm.status,
                id: this.ruleForm.id,
                seatName: this.ruleForm.seatName,
              };
              updateSeatPlanAPI(params).then((res) => {
                if (res.code == "SUCCESS") {
                  this.dialogVisible = false;
                  this.$parent.getQuerySeatPlanPage();
                }
              });
            }
          }
        });
      } else if (str === "cancel") {
        this.dialogVisible = false;
      }
    },
    //操作座位  编辑删除
    handleSeatBox(command, index) {
      this.handleSeatBoxIndex = index;
      if (command === "edit") {
        this.configureDialogVisible = true;
        this.signSeatNum = this.form.seatNum;
      } else if (command === "delete") {
        this.$alert("是否删除该座位？", "是否删除", {
          confirmButtonText: "确定",
          callback: () => {
            this.seatData[this.handleSeatBoxIndex].id = -1;
            this.seatData[this.handleSeatBoxIndex].type = 0;
          },
        });
      }
    },
    //操作配置座位弹窗
    handleConfigureDialog(str) {
      if (str === "save") {
        if (this.form.seatType === 2) {
          for (let index = 0; index < this.seatData.length; index++) {
            if (this.seatData[index].id === this.form.seatNum) {
              this.$message({
                message: "座位编号已存在，请重新选择座位编号！",
                type: "warning",
              });
              return;
            }
          }
        }
        this.seatData[this.handleSeatBoxIndex].id =
          this.form.seatType === 2 ? this.form.seatNum : 0;
        this.seatData[this.handleSeatBoxIndex].type = this.form.seatType;
        this.form.seatType === 2 && this.form.seatNum++;
      } else {
        this.form.seatNum = this.signSeatNum;
      }
      this.configureDialogVisible = false;
    },
    //鼠标移入座位事件
    handleMouseover(row) {
      if (this.dialogTitle !== "新增座位布局") return;
      row.isHoverFlag = true;
    },
    //鼠标移出座位事件
    handleMouseleave(row) {
      if (this.dialogTitle !== "新增座位布局") return;
      row.isHoverFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.seatSelectionDialog-page {
  .demo-ruleForm {
    .el-input {
      width: 272px;
    }
    .el-select {
      width: 190px;
    }
    .seatNum {
      ::v-deep .el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }
  }
  .seat-selection-box {
    width: 100%;
    height: 410px;
    overflow: scroll;
    background: #f7f8f9;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: space-around;
    padding: 5px 0;
    .box-left {
      margin-top: 36px;
      .left-label {
        color: #c7c8c9;
        display: inline-block;
        width: 60px;
        text-align: end;
      }
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .box-mid {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .row-box {
        width: 100px;
        height: 100px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .bg-box {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #336ffe;
          font-weight: bold;
        }
        .selected-bg {
          background-image: url("../../../assets/images/home/selected.svg");
        }
        .not-selected-bg {
          background-image: url("../../../assets/images/home/not_selected.svg");
        }
        .el-dropdown {
          position: absolute;
          top: 10px;
          right: 5px;
        }
        .el-icon-more {
          transform: rotate(90deg);
          cursor: pointer;
        }
      }
      .aisle-box {
        background: #f2f2f2;
      }
      .passenger-box {
        background: #ffffff !important;
        border-color: #336ffe !important;
      }
    }
    .box-right {
      text-align: center;
      & > div {
        margin-bottom: 12px;
      }
      img {
        width: 24px;
        height: 24px;
      }
      .aisle-box {
        width: 17px;
        height: 17px;
        background: #d9d9d9;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 0 auto;
      }
    }
  }
}
</style>
